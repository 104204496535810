export const strict = false

export const state = () => ({
  user: null,
  error: '',
  minimizeVideo: null,
  showMinimize: false,
  ratingType: '',
  enterData: {
    notifications: 0,
    top_anime: []
  },
  setShowAuthForm: false,
  feedback: false,
  isDesktop: false,
  tabActive: 1,
  whisperStore: null
})

export const mutations = {
  setTabActive(state, payload) {
    state.tabActive = payload
  },
  setShowAuthForm(state, payload) {
    state.setShowAuthForm = payload
    setTimeout(() => {
      state.setShowAuthForm = false
    }, 2000)
  },
  minimizeVideo(state, payload) {
    state.minimizeVideo = payload
  },
  setWhisper(state, payload) {
    state.whisperStore = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    if (typeof payload === 'string') {
      payload = {
        status: 'info',
        msg: payload
      }
    }
    state.error = payload
  },
  clearError(state) {
    state.error = ''
  },
  setRatingType(state, payload) {
    state.ratingType = payload
  },
  setEnterData(state, payload) {
    state.enterData = payload
  },
  toggleFeedback(state, payload) {
    state.feedback = payload
  },
  setDevice(state, payload) {
    state.isDesktop = payload
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) commit('setUser', req.user)

      commit('setDevice', app.$device.isDesktop)

      const { data } = await app.$axios.get('/api/enterpoint')
      if (!data.error) {
        commit('setEnterData', data)
      }
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}
