import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d7d8fa4 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _a57a19d8 = () => interopDefault(import('../pages/cinema/index.vue' /* webpackChunkName: "pages/cinema/index" */))
const _f1a5421a = () => interopDefault(import('../pages/clips.vue' /* webpackChunkName: "pages/clips" */))
const _5077fd97 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _abb074e0 = () => interopDefault(import('../pages/cp/index.vue' /* webpackChunkName: "pages/cp/index" */))
const _795b8512 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _5b503ef5 = () => interopDefault(import('../pages/donate1/index.vue' /* webpackChunkName: "pages/donate1/index" */))
const _d972f42a = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _316339ca = () => interopDefault(import('../pages/last-comments.vue' /* webpackChunkName: "pages/last-comments" */))
const _509608f0 = () => interopDefault(import('../pages/new.vue' /* webpackChunkName: "pages/new" */))
const _37298a38 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _28412310 = () => interopDefault(import('../pages/person/index.vue' /* webpackChunkName: "pages/person/index" */))
const _31111712 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _511a1cae = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _7d34a079 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _4e0bb3ba = () => interopDefault(import('../pages/random.vue' /* webpackChunkName: "pages/random" */))
const _2851d7f7 = () => interopDefault(import('../pages/schedule.vue' /* webpackChunkName: "pages/schedule" */))
const _b086a6f0 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4a8781b1 = () => interopDefault(import('../pages/store.vue' /* webpackChunkName: "pages/store" */))
const _438824cc = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _270499d3 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _7f29d810 = () => interopDefault(import('../pages/term-of-service.vue' /* webpackChunkName: "pages/term-of-service" */))
const _788dca1d = () => interopDefault(import('../pages/updates/index.vue' /* webpackChunkName: "pages/updates/index" */))
const _595acfdb = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _7650a8b9 = () => interopDefault(import('../pages/vmeste/index.vue' /* webpackChunkName: "pages/vmeste/index" */))
const _4bea79e2 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _0309e16f = () => interopDefault(import('../pages/cp/articles.vue' /* webpackChunkName: "pages/cp/articles" */))
const _158e2a4f = () => interopDefault(import('../pages/cp/blogs.vue' /* webpackChunkName: "pages/cp/blogs" */))
const _756d3a0a = () => interopDefault(import('../pages/cp/chat.vue' /* webpackChunkName: "pages/cp/chat" */))
const _0b12f2ba = () => interopDefault(import('../pages/cp/cinema.vue' /* webpackChunkName: "pages/cp/cinema" */))
const _49f639a6 = () => interopDefault(import('../pages/cp/comments.vue' /* webpackChunkName: "pages/cp/comments" */))
const _5c6360c3 = () => interopDefault(import('../pages/cp/desc.vue' /* webpackChunkName: "pages/cp/desc" */))
const _be2821be = () => interopDefault(import('../pages/cp/donate.vue' /* webpackChunkName: "pages/cp/donate" */))
const _9abdd292 = () => interopDefault(import('../pages/cp/feedback.vue' /* webpackChunkName: "pages/cp/feedback" */))
const _0175325e = () => interopDefault(import('../pages/cp/kino.vue' /* webpackChunkName: "pages/cp/kino" */))
const _004792d9 = () => interopDefault(import('../pages/cp/reviews.vue' /* webpackChunkName: "pages/cp/reviews" */))
const _33c54b68 = () => interopDefault(import('../pages/cp/tasks.vue' /* webpackChunkName: "pages/cp/tasks" */))
const _6b845ee6 = () => interopDefault(import('../pages/donate1/success.vue' /* webpackChunkName: "pages/donate1/success" */))
const _54038ee2 = () => interopDefault(import('../pages/blogs/_id/index.vue' /* webpackChunkName: "pages/blogs/_id/index" */))
const _58030713 = () => interopDefault(import('../pages/download/_id.vue' /* webpackChunkName: "pages/download/_id" */))
const _9253a4fc = () => interopDefault(import('../pages/person/_type/index.vue' /* webpackChunkName: "pages/person/_type/index" */))
const _78787967 = () => interopDefault(import('../pages/updates/_type/index.vue' /* webpackChunkName: "pages/updates/_type/index" */))
const _f4fde5fa = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _7598f53e = () => interopDefault(import('../pages/vmeste/_id.vue' /* webpackChunkName: "pages/vmeste/_id" */))
const _734fd3cd = () => interopDefault(import('../pages/watch/_id/index.vue' /* webpackChunkName: "pages/watch/_id/index" */))
const _d43f22ec = () => interopDefault(import('../pages/blogs/_id/_article.vue' /* webpackChunkName: "pages/blogs/_id/_article" */))
const _5a122c1a = () => interopDefault(import('../pages/person/_type/_name.vue' /* webpackChunkName: "pages/person/_type/_name" */))
const _94e75dbc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _7d7d8fa4,
    name: "blogs"
  }, {
    path: "/cinema",
    component: _a57a19d8,
    name: "cinema"
  }, {
    path: "/clips",
    component: _f1a5421a,
    name: "clips"
  }, {
    path: "/copyright",
    component: _5077fd97,
    name: "copyright"
  }, {
    path: "/cp",
    component: _abb074e0,
    name: "cp"
  }, {
    path: "/donate",
    component: _795b8512,
    name: "donate"
  }, {
    path: "/donate1",
    component: _5b503ef5,
    name: "donate1"
  }, {
    path: "/download",
    component: _d972f42a,
    name: "download"
  }, {
    path: "/last-comments",
    component: _316339ca,
    name: "last-comments"
  }, {
    path: "/new",
    component: _509608f0,
    name: "new"
  }, {
    path: "/notifications",
    component: _37298a38,
    name: "notifications"
  }, {
    path: "/person",
    component: _28412310,
    name: "person"
  }, {
    path: "/policy",
    component: _31111712,
    name: "policy"
  }, {
    path: "/popular",
    component: _511a1cae,
    name: "popular"
  }, {
    path: "/profile",
    component: _7d34a079,
    name: "profile"
  }, {
    path: "/random",
    component: _4e0bb3ba,
    name: "random"
  }, {
    path: "/schedule",
    component: _2851d7f7,
    name: "schedule"
  }, {
    path: "/search",
    component: _b086a6f0,
    name: "search"
  }, {
    path: "/store",
    component: _4a8781b1,
    name: "store"
  }, {
    path: "/subscribe",
    component: _438824cc,
    name: "subscribe"
  }, {
    path: "/success",
    component: _270499d3,
    name: "success"
  }, {
    path: "/term-of-service",
    component: _7f29d810,
    name: "term-of-service"
  }, {
    path: "/updates",
    component: _788dca1d,
    name: "updates"
  }, {
    path: "/users",
    component: _595acfdb,
    name: "users"
  }, {
    path: "/vmeste",
    component: _7650a8b9,
    name: "vmeste"
  }, {
    path: "/watch",
    component: _4bea79e2,
    name: "watch"
  }, {
    path: "/cp/articles",
    component: _0309e16f,
    name: "cp-articles"
  }, {
    path: "/cp/blogs",
    component: _158e2a4f,
    name: "cp-blogs"
  }, {
    path: "/cp/chat",
    component: _756d3a0a,
    name: "cp-chat"
  }, {
    path: "/cp/cinema",
    component: _0b12f2ba,
    name: "cp-cinema"
  }, {
    path: "/cp/comments",
    component: _49f639a6,
    name: "cp-comments"
  }, {
    path: "/cp/desc",
    component: _5c6360c3,
    name: "cp-desc"
  }, {
    path: "/cp/donate",
    component: _be2821be,
    name: "cp-donate"
  }, {
    path: "/cp/feedback",
    component: _9abdd292,
    name: "cp-feedback"
  }, {
    path: "/cp/kino",
    component: _0175325e,
    name: "cp-kino"
  }, {
    path: "/cp/reviews",
    component: _004792d9,
    name: "cp-reviews"
  }, {
    path: "/cp/tasks",
    component: _33c54b68,
    name: "cp-tasks"
  }, {
    path: "/donate1/success",
    component: _6b845ee6,
    name: "donate1-success"
  }, {
    path: "/blogs/:id",
    component: _54038ee2,
    name: "blogs-id"
  }, {
    path: "/download/:id",
    component: _58030713,
    name: "download-id"
  }, {
    path: "/person/:type",
    component: _9253a4fc,
    name: "person-type"
  }, {
    path: "/updates/:type",
    component: _78787967,
    name: "updates-type"
  }, {
    path: "/users/:id",
    component: _f4fde5fa,
    name: "users-id"
  }, {
    path: "/vmeste/:id",
    component: _7598f53e,
    name: "vmeste-id"
  }, {
    path: "/watch/:id",
    component: _734fd3cd,
    name: "watch-id"
  }, {
    path: "/blogs/:id/:article",
    component: _d43f22ec,
    name: "blogs-id-article"
  }, {
    path: "/person/:type/:name",
    component: _5a122c1a,
    name: "person-type-name"
  }, {
    path: "/",
    component: _94e75dbc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
