<template>
  <main class="error-page">
    <section v-if="error.statusCode === 404">
      <h1>
        {{ error.message }}
      </h1>
      <img src="/error-page-404.png" :alt="error.message">
      <p>
        Воспользуйтесь поиском <nuxt-link to="/search">по сайту</nuxt-link> или перейдите <nuxt-link to="/">на главную</nuxt-link> страницу.
      </p>
    </section>
    <section v-else-if="error.statusCode === 401">
      <h1>
        {{ error.message }}
      </h1>
      <div class="auth-social">
        <!-- <span @click="authSocial('facebook')" class="facebook"><i /> Facebook</span> -->
        <span @click="authSocial('google')" class="google"><i /> Google</span>
        <!-- <span @click="authSocial('vkontakte')" class="vkontakte"><i /> ВКонтакте</span> -->
        <!-- <span @click="authSocial('odnoklassniki')" class="odnoklassniki"><i /> Одноклассники</span> -->
        <span @click="authSocial('mailru')" class="mailru"><i /> Mail.ru</span>
        <span @click="authSocial('yandex')" class="yandex"><i /> Яндекс</span>
      </div>
    </section>
    <section v-else>
      <h1>
        {{ error.message }}
      </h1>
    </section>
  </main>
</template>

<script>
export default {
  props: { error: { type: Object, default: () => ({}) } },
  head() {
    return {
      title: `Упс... Возникла исключительная ситуация...`
    }
  },
  methods: {
    authSocial(social) {
      location.href = `/api/auth/${social}`
    }
  }
}
</script>

<style lang="stylus">
.error-page
  text-align center
  .auth-social
    grid-template-columns repeat(2, 1fr)
  img
    max-width 100%
    margin 40px 0
</style>
